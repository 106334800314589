import React, { useEffect, useRef } from 'react'
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google'
import { Field, LayoutServiceData, Placeholder } from '@sitecore-jss/sitecore-jss-nextjs'
import { useRouter } from 'next/router'

import { PageStateProvider } from 'components/front-end/_layoutContext'
import SharedContextProvider from 'components/front-end/_sharedContext'
import { NavigationProvider } from 'components/front-end/Header/NavigationProvider'
import { ShortlistProvider } from 'components/front-end/Shortlist/ShortlistProvider'
import ADFMetadata from 'components/front-end/SiteMetadata/Metadata'
import { TwIndicator } from 'components/front-end/Utility/TwIndicator'
import { trackPageLoad } from 'utils/tracking'
import Scripts from 'src/Scripts' // TODO: Andrei - Is this doing anything?

/**
 * This Layout is needed for Starter Kit.
 */

// Prefix public assets with a public URL to enable compatibility with Sitecore Experience Editor.
// If you're not supporting the Experience Editor, you can remove this.
// const publicUrl = getPublicUrl()

interface LayoutProps {
  layoutData: LayoutServiceData
}

export interface RouteFields {
  [key: string]: unknown
  Title?: Field
}

export const generateClasses = (fields: RouteFields | undefined) => {
  const themes = ['light', 'dark']
  const service = (fields?.['Service'] as Field<string>)?.value
  const theme = (fields?.['Theme'] as Field<string>)?.value ?? themes[0]
  const bg =
    theme === themes[0]
      ? ['bg-white', 'text-grey-medium', 'font-inter']
      : ['dark', theme === 'grey' ? 'bg-black' : 'bg-black-off', 'text-white', 'font-inter']

  const bodyClasses = []
  if (theme) {
    bodyClasses.push(theme)
  }
  if (service) {
    bodyClasses.push(service)
  }
  if (bg) {
    bodyClasses.push(...bg)
  }
  if (theme === 'grey') {
    bodyClasses.push('[&_main]:mt-[-65px]', 'md:[&_main]:-mt-22')
  }
  return bodyClasses
}

const Layout = ({ layoutData }: LayoutProps): JSX.Element => {
  const router = useRouter()
  const { route } = layoutData.sitecore
  const fields = route?.fields as RouteFields
  const isPageEditing = layoutData.sitecore.context.pageEditing

  // Can be used as data for static pages - uncomment this when you need it.
  // console.log(layoutData)

  useEffect(() => {
    // Sitecore has capital names for Theme and Service
    const body = window.document?.body
    const bodyClasses = generateClasses(fields)
    body.classList.add(...bodyClasses)

    return () => {
      body.classList.remove(...bodyClasses)
    }
  }, [route, fields])

  const previousRoute = useRef('')

  useEffect(() => {
    const handleRouteChange = () => {
      // Prevent duplicate events
      if (previousRoute.current === router.asPath) {
        return
      }

      trackPageLoad()

      if (previousRoute.current) {
        // Simulate a `js`, `dom`, and `load` events so GTM can track client-side navigation
        sendGTMEvent({ event: 'gtm.js' })
        sendGTMEvent({ event: 'gtm.dom' })
        sendGTMEvent({ event: 'gtm.load' })
      }

      previousRoute.current = router.asPath
    }

    // Fire on initial page load
    handleRouteChange()

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  return (
    <>
      <Scripts />

      <ADFMetadata layoutData={layoutData} />

      {/* root placeholder for the app, which we add components to using route data */}
      <PageStateProvider>
        <ShortlistProvider>
          <NavigationProvider>
            <SharedContextProvider>
              <div className={isPageEditing ? 'editing-mode' : 'prod-mode'}>
                <header className="relative z-50">
                  <div id="header">{route && <Placeholder name="headless-header" rendering={route} />}</div>
                </header>
                <main className="min-h-[500px]">
                  <div id="content">{route && <Placeholder name="headless-main" rendering={route} />}</div>
                </main>
                <footer>
                  <div id="footer">{route && <Placeholder name="headless-footer" rendering={route} />}</div>
                </footer>
                <div id="drawers" role="complementary">
                  {route && <Placeholder name="headless-drawers" rendering={route} />}
                </div>
              </div>
            </SharedContextProvider>
          </NavigationProvider>
        </ShortlistProvider>
      </PageStateProvider>
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GA_ID as string} />
      <TwIndicator />
    </>
  )
}

export default Layout
